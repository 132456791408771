import { getCommonServerSideProps } from '../api/getMainServerSideProps'
import { wrapper } from '../store'
import {HomePage} from '../feature-pages';
import { getUniqueId } from '../utils'

export const getServerSideProps = wrapper.getServerSideProps(
    (store) => async (context) => {
        const commonProps = await getCommonServerSideProps(store, context);
        await HomePage.getSSRProps(store, context)

        return {
            props: {
                ...commonProps.props,
                // Reset page state on redirect to index page.
                // Ex: click logo in header, being on index page
                key: getUniqueId(),
            },
        }
    }
)

export default HomePage
